import React from "react";

export default function Hollywoodd() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/hollywood1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/hollywood2.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">Odontoiatria e trattamenti dentali </h4>
                  <span>Hollywood smile e Faccette</span>
                </div>

                <div className="text-content mt-20">
                  <p>
                    Cos'è il Hollywood Smile? Il Hollywood Smile è una procedura
                    estetica molto popolare in Turchia, che mira a creare un
                    sorriso perfettamente equilibrato e armonioso. Un sorriso
                    attraente si basa su diversi parametri, tra cui:
                    <br /> • L’allineamento e lo spazio tra i denti, che seguono
                    proporzioni precise. <br /> • L'assenza di spazi vuoti tra i
                    denti.
                    <br />
                    • Gengive simmetriche che circondano i denti in modo
                    armonioso. <br /> • La visibilità delle gengive sotto le
                    labbra durante il sorriso è misurata per essere
                    proporzionata.
                    <br /> • Il numero di denti visibili quando si sorride è
                    ottimizzato. <br /> • I denti sono allineati simmetricamente
                    rispetto alla linea mediana del viso. <br /> • Gengive sane
                    e un corretto posizionamento dei denti per assicurare una
                    perfetta fonazione. Il colore, la forma dei denti, la
                    posizione delle labbra e persino il colore della pelle sono
                    considerati per personalizzare il design del sorriso per
                    ogni individuo. Per ottenere il miglior risultato, vengono
                    effettuate misurazioni accurate, foto, video e radiografie.
                  </p>
                  <h5>Metodi del Hollywood Smile</h5>
                  <p>
                    Ci sono diverse procedure utilizzate per raggiungere il
                    sorriso estetico desiderato: <br />• Gengivectomia:
                    riduzione del tessuto gengivale in eccesso. <br />•
                    Sbiancamento dei denti: per un sorriso più luminoso.
                    <br /> • Trattamenti implantari: per sostituire i denti
                    mancanti. <br />• Corone in zirconio: per migliorare
                    l'estetica e la funzionalità dei denti.
                    <br /> • Lumineers: faccette sottili che migliorano la forma
                    e il colore dei denti.
                    <br /> • Estetica rosa: per migliorare l’aspetto delle
                    gengive e ottenere un sorriso armonioso. Queste tecniche
                    possono essere combinate per creare il sorriso perfetto,
                    adattato alle caratteristiche uniche di ogni persona. Le
                    faccette dentali invece sono un tipo di restauro estetico
                    che consiste nell'applicazione di sottili strati di
                    materiali specifici sulla superficie dei denti, con lo scopo
                    di migliorare l'aspetto e la funzionalità del sorriso.
                    Vengono utilizzate per correggere una serie di problemi, tra
                    cui carie, denti danneggiati o rotti, macchie e difetti di
                    forma. Le faccette sono un'opzione terapeutica in quei casi
                    in cui le otturazioni non sono sufficienti o non riparabili.
                    Se i denti presentano scolorimento o deformazioni evidenti,
                    le faccette possono essere applicate per ripristinare un
                    aspetto più naturale e attraente. Durante il processo,
                    potrebbe essere necessario rimuovere una piccola quantità di
                    smalto dentale, ma in alcuni casi è possibile applicare le
                    faccette senza alcuna abrasione. La quantità di smalto
                    rimossa varia in base al tipo di faccetta utilizzata e alle
                    aspettative estetiche del paziente. Il trattamento è
                    personalizzato per soddisfare le esigenze specifiche di ogni
                    persona, offrendo un sorriso rinnovato e armonioso.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
