import React, { useState } from "react";
import ModalVideo from "react-modal-video";

export default function Hero() {
  const [isOpen, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://localhost:3000", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const data = await response.json();
      setSuccessMessage(data.message || "Message sent successfully!");
      setError("");
      setFormData({
        name: "",
        phone: "",
        email: "",
        message: "",
      });
    } catch (error) {
      setError("Failed to send message. Please try again later.");
      console.error(error);
    }
  };

  return (
    <>
      <section
        className="hero-section background-img pt-100"
        style={{
          background: "linear-gradient(to right, #0000FF, #1E90FF)",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="hero-content-left position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                <h1 className="text-white">
                  <span>SIAMO QUA PER RAVVIVARE, </span> RINGIOVANIRE E
                  RISOLVERE I VOSTRI PROBLEMI ESTETICI,
                  <br /> RINCONQUISTA LA FIDUCIA IN TE STESSO.
                </h1>
                <p className="lead">
                  MEDICAL Plus offre un supporto completo nella pianificazione
                  del viaggio a Istanbul, includendo un servizio dedicato per la
                  ricerca dei voli più convenienti in base alle tue esigenze.
                  Per assicurare il massimo comfort, collaboriamo con un hotel a
                  5 stelle situato nel cuore di Istanbul, con colazione inclusa
                  e accesso alla SPA, tutto compreso nel pacchetto. Dopo il
                  rientro a casa, il nostro servizio di follow-up ti seguirà
                  costantemente, fornendo assistenza continua tramite
                  videochiamate, chat e contatti telefonici. Inoltre, siamo
                  sempre disponibili nella nostra sede di Roma per qualsiasi
                  necessità. Particolare attenzione sarà dedicata alla fase
                  iniziale di guarigione post-intervento, seguita dai nostri
                  esperti per garantire un recupero ottimale. MEDICAL Plus ti
                  aspetta con il massimo della qualità, pronti a rimanere in
                  contatto con te e a farti vivere un'esperienza
                  indimenticabile di ospitalità.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-lg-5">
              <div className="sign-up-form-wrap position-relative rounded p-5 gray-light-bg mt-5">
                <div className="sign-up-form-header text-center mb-4">
                  <h4 className="mb-0">
                    CONTATTA SUBITO I NOSTRI PROFESSIONISTI{" "}
                  </h4>
                  <p>Get response within 24 hours</p>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                {successMessage && (
                  <div className="alert alert-success">{successMessage}</div>
                )}
                <form onSubmit={handleSubmit} className="sign-up-form">
                  <div className="form-group input-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Nome :"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group input-group">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder="Num. cellulare :"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>{" "}
                  <div className="form-group input-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="email :"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group input-group">
                    <textarea
                      name="message"
                      id="msg"
                      className="form-control"
                      placeholder="spiegaci il tuo problema e le tue aspettative"
                      cols="30"
                      rows="4"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      name="submit"
                      id="submit"
                      className="btn solid-btn btn-block"
                      value="Send"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId="9No-FiEInLA"
            onClose={() => setOpen(false)}
          />
        </div>
        <div className="section-shape position-absolute">
          <img src="assets/img/wave-shap.svg" alt="shape" />
        </div>
      </section>
    </>
  );
}
