import React from "react";

export default function Manicaa() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/manica.png"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">CHIRURGIA BARIATRICA</h4>
                  <span>Manica gastrica o sleeve gastrectomy</span>
                </div>

                <div className="text-content mt-20">
                  <p>
                    Cos'è la Sleeve Gastrectomy? La sleeve gastrectomy, nota
                    anche come manica gastrica, è una procedura chirurgica che
                    riduce le dimensioni dello stomaco rimuovendone una parte
                    attraverso un intervento laparoscopico (minimamente
                    invasivo). Dopo l'operazione, i pazienti possono perdere
                    peso più facilmente, rendendo questa tecnica una scelta
                    popolare per il trattamento dell'obesità, soprattutto negli
                    ultimi anni.
                  </p>
                  <h5>Sleeve Gastrectomy in Turchia</h5>
                  <p>
                    Questa operazione è diventata sempre più comune per diverse
                    ragioni. Tra le principali ci sono la relativa semplicità
                    dell'intervento, il basso rischio di complicanze e la sua
                    efficacia come metodo sicuro per perdere peso. L'obesità è
                    una condizione che deve essere trattata in modo tempestivo
                    poiché può portare a una serie di malattie. Se i pazienti
                    non riescono a raggiungere il loro peso ideale, rischiano di
                    sviluppare malattie croniche come il diabete, l'ipertensione
                    e patologie cardiache. Per evitare questi rischi e vivere
                    una vita più sana, i pazienti che non riescono a perdere
                    peso con metodi tradizionali, come dieta ed esercizio
                    fisico, dovrebbero considerare la sleeve gastrectomy. Subito
                    dopo l'intervento, i risultati sono evidenti: già nel primo
                    mese, è possibile perdere tra i 15 e i 20 chili, in media.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
