import React from "react";

export default function Gastricoo() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img"></div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">CHIRURGIA BARIATRICA</h4>
                  <span>BYPASS GASTRICO</span>
                </div>

                <div className="text-content mt-20">
                  <h5>Cos'è il Bypass Gastrico? </h5>
                  <p>
                    Il bypass gastrico è uno degli interventi chirurgici più
                    comuni per il trattamento dell'obesità, noto per i suoi
                    risultati altamente efficaci. Durante questa procedura, sia
                    il volume dello stomaco sia l'assorbimento dei nutrienti
                    vengono ridotti, facilitando la perdita di peso. In Turchia,
                    il bypass gastrico comporta una modifica delle strutture
                    dello stomaco e dell'intestino tenue, permettendo ai
                    pazienti di raggiungere un calo ponderale significativo.
                    Questo intervento è considerato sia restrittivo (perché
                    riduce la quantità di cibo che si può assumere) sia
                    malassorbitivo (perché diminuisce l'assorbimento dei
                    nutrienti). Nella chirurgia di bypass gastrico, il volume
                    dello stomaco viene drasticamente ridotto. La parte
                    superiore dello stomaco viene separata dal resto, creando
                    una piccola sacca con una capacità di circa 30-50 cc.
                    Successivamente, una parte dell'intestino tenue viene
                    bypassata e collegata direttamente a questa nuova sacca
                    gastrica. Questo modifica il percorso del cibo nel tratto
                    digestivo, portando i pazienti a sentirsi sazi molto più
                    rapidamente, anche con porzioni ridotte. Inoltre, una parte
                    significativa dei nutrienti, in particolare quelli
                    ipercalorici, viene assorbita in misura minore, contribuendo
                    ulteriormente alla perdita di peso.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
