import React from "react";

export default function Palloncinoo() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/gastro1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">CHIRURGIA BARIATRICA</h4>
                  <span>PALLONCINO GASTRICO</span>
                </div>

                <div className="text-content mt-20">
                  <p>
                    Cos'è il Palloncino Gastrico? Il palloncino gastrico è un
                    metodo non chirurgico e ampiamente utilizzato per il
                    trattamento dell'obesità. Questa tecnica, introdotta per la
                    prima volta nel 1982, consiste nell'inserire un palloncino
                    nello stomaco che occupa spazio, creando una sensazione di
                    sazietà e aiutando le persone a ridurre l'assunzione di
                    cibo. Il palloncino gastrico è stato sviluppato per colmare
                    il divario tra la dieta e l'esercizio fisico da soli e le
                    procedure chirurgiche bariatriche più complesse. Negli
                    ultimi anni, è diventato una soluzione popolare per le
                    persone in sovrappeso o obese che non sono ancora candidate
                    per un intervento chirurgico. Questa procedura viene
                    utilizzata anche come preparazione per la chirurgia
                    dell'obesità, permettendo una rapida perdita di peso prima
                    dell'intervento. In questo modo, si possono prendere
                    precauzioni per evitare che l'eccesso di peso evolva in
                    obesità patologica, abbinando la procedura a una corretta
                    dieta ed esercizio fisico.
                  </p>
                  <h5>Tipi di Palloncino Gastrico</h5>
                  <p>
                    I palloncini gastrici, pur avendo un meccanismo d'azione
                    simile, possono variare in base a caratteristiche come la
                    durata della permanenza nello stomaco e la possibilità di
                    regolazione del volume.
                    <br /> Palloncini a Volume Fisso:
                    <br /> 1. Questi palloncini vengono gonfiati con un volume
                    di 400-600 ml al momento dell'inserimento e il loro volume
                    rimane invariato nel tempo. <br /> 2. Restano nello stomaco
                    per un massimo di 6 mesi, dopodiché vengono rimossi. <br />{" "}
                    3. Un tipo specifico è il palloncino gastrico deglutibile
                    (palloncino Ellipse), che non richiede endoscopia per
                    l'inserimento. Dopo 4 mesi, il palloncino si svuota e viene
                    espulso naturalmente attraverso l'intestino, senza bisogno
                    di ulteriori interventi.
                    <br /> Palloncini Gastrici Regolabili: <br /> 4. Questi
                    palloncini permettono di regolare il loro volume mentre sono
                    nello stomaco, adattandosi alle esigenze del paziente.
                    <br /> 5. Inizialmente vengono gonfiati con 400-500 ml, ma
                    il volume può essere modificato successivamente in base alla
                    perdita di peso del paziente.
                  </p>
                  <h5>Procedura di Inserimento del Palloncino Gastrico</h5>
                  <p>
                    Ad eccezione del palloncino Ellipse ingeribile,
                    l'inserimento dei palloncini gastrici richiede una sedazione
                    leggera. Questa sedazione induce uno stato di sonnolenza ma
                    non è comparabile all'anestesia generale, in quanto non
                    richiede attrezzature per la respirazione assistita. Il
                    rischio associato a questa procedura è molto basso rispetto
                    a interventi chirurgici più invasivi.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
