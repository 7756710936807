import React from "react";

const Seno = ({ hasTitle, bgColor }) => {
  const imageStyle = {
    width: "250px",
    height: "250px",
    objectFit: "cover",
    display: "block",
    margin: "0 auto", // Centrer l'image sur les petits écrans
    position: "relative", // S'assurer que le conteneur de l'image est relatif
    // Suppression de borderRadius pour rendre les images carrées
  };

  return (
    <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-8">
            <div className="section-heading text-center mb-5">
              {hasTitle && (
                <span className="text-uppercase color-secondary sub-title">
                  Lovely Team
                </span>
              )}
              <h2>Before/After</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <img
              src="assets/img/seno1.png"
              alt="Team Member"
              className="img-fluid"
              style={imageStyle}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <img
              src="assets/img/seno2.png"
              alt="Team Member"
              className="img-fluid"
              style={imageStyle}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <img
              src="assets/img/seno3.png"
              alt="Team Member"
              className="img-fluid"
              style={imageStyle}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <img
              src="assets/img/seno4.png"
              alt="Team Member"
              className="img-fluid"
              style={imageStyle}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Seno;
