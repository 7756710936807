import React from "react";
import Layout from "../../components/layout/Layout";
import Navbar from "../../components/layout/header/Navbar";
import HeroSix from "./Hero";
import Team from "../../components/pages-description/Service";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/layout/footer/Footer";
import AboutTwo from "../../components/about/AboutTwo";

export default function Home() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroSix />
      <AboutTwo />
      <Team />
      <Contact bgColor="gray-light-bg" />
      <Footer space />
    </Layout>
  );
}
