import React from "react";

export default function AboutTwo() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="about-content-right">
                <img
                  src="assets/img/home.jpg"
                  width="500"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <h2>
                Paziente e chirurgo: un rapporto basato sulla fiducia,
                trasparenza e competenza.
              </h2>
              <p className="mb-4 lh-190">
                MEDICAL Plus, fondata nel 2001, è oggi una delle organizzazioni
                sanitarie più rinomate di Istanbul, in Turchia. Con numerose
                sedi nella città, più di 50 reparti, oltre 4000 posti letto e
                più di 1000 medici specialisti altamente qualificati, l'azienda
                è in continua e rapida crescita. Grazie alla nostra lunga
                esperienza nel settore sanitario, abbiamo costantemente
                migliorato e sviluppato la qualità dei nostri servizi,
                integrando le tecnologie e le tecniche più avanzate. Con la
                fiducia dei nostri pazienti, il nostro personale ti accoglierà
                con piacere nella splendida Istanbul, facendoti sentire a casa e
                permettendoti di affrontare il tuo percorso con la massima
                serenità. Sarai seguito fin dal primo giorno dai nostri
                collaboratori e interpreti che parlano perfettamente l'italiano.
                Offriamo pacchetti completi che includono tutto, ad eccezione
                del biglietto aereo. Dal momento del tuo arrivo fino alla tua
                partenza, ci occupiamo di ogni dettaglio, dai trasferimenti con
                autista privato all'alloggio VIP, dagli interventi alle visite,
                garantendo un servizio esclusivo a 360 gradi con altissima
                qualità e prezzi accessibili per tutti. Sei nel posto giusto.
                Contatta subito i nostri medici.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
