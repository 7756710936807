import React from "react";

export default function Liftingg() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/lift1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/lift2.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1"> CHIRURGIA PLASTICA</h4>
                  <span>LIFTING BRACCIA E COSCIA </span>
                </div>

                <div className="text-content mt-20">
                  <h5>Cos'è il lifting delle cosce ?</h5>
                  <p>
                    Il lifting della coscia è una procedura di chirurgia
                    estetica che consente di correggere sproporzioni e
                    migliorare l'aspetto delle cosce. Attraverso l'uso di
                    protesi, iniezioni di grasso o altri riempitivi, è possibile
                    ridare tonicità e volume alle cosce, offrendo una silhouette
                    più equilibrata. Questa procedura è indicata per chi
                    presenta fianchi piatti, cadenti o privi di forma, spesso
                    per motivi genetici. L'iniezione di grasso, prelevato da
                    aree come la vita o l'addome, è la tecnica più sicura e
                    preferita, poiché utilizza il grasso corporeo del paziente
                    stesso. Al contrario, protesi e riempitivi artificiali sono
                    meno utilizzati, poiché non sempre si adattano a tutti. Il
                    lifting della coscia può correggere con successo cosce
                    eccessivamente piatte o rilassate, migliorando l'aspetto
                    generale del corpo.
                  </p>
                  <h5>Cos'è il lifting alle braccia ?</h5>
                  <p>
                    La brachioplastica, o lifting delle braccia, è un intervento
                    estetico mirato a correggere il rilassamento e l'eccesso di
                    pelle nella parte superiore delle braccia. Fattori come
                    l'età o l'aumento di peso possono causare un cedimento della
                    pelle in questa zona. Mentre in alcuni casi lo sport e gli
                    esercizi possono aiutare a migliorare l'aspetto, nei casi
                    più avanzati è necessario ricorrere a un intervento
                    chirurgico. L'operazione di lifting delle braccia rimuove
                    l'eccesso di pelle e tessuto, rassodando e rimodellando la
                    parte superiore del braccio. Quando il rilassamento è grave,
                    solo un intervento chirurgico può garantire risultati
                    soddisfacenti, specialmente se vi è un notevole accumulo di
                    pelle in eccesso.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
