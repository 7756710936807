import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Impiantii from "../../components/pages-description/Odontoiatria/impianti";
import Contact from "../../components/contact/Contact";

export default function Impianti() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Odontoiatria e trattamenti dentali"
        PageTitle="impianti e chirugia odontoiatrica"
      />
      <Impiantii />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
