import React from "react";

export default function Sixx() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/six1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1"> CHIRURGIA PLASTICA</h4>
                  <span>GINECOMASTIA E SIX PACK</span>
                </div>

                <div className="text-content mt-20">
                  <h5>Cos'è la Ginecomastia?</h5>
                  <p>
                    La ginecomastia è una condizione caratterizzata dalla
                    crescita anomala del tessuto mammario negli uomini, simile a
                    quello femminile. Questa situazione, che spesso si manifesta
                    durante la pubertà a causa di squilibri ormonali, può
                    causare disagio estetico e psicologico se persiste nel
                    tempo. La chirurgia per la ginecomastia, molto diffusa in
                    Turchia, è un intervento che mira a ridurre l'eccessivo
                    sviluppo del seno maschile, restituendo un aspetto più
                    tonico e proporzionato. Le cause della ginecomastia sono
                    molteplici e possono includere fattori ormonali, l'uso di
                    farmaci, nonché predisposizioni genetiche e strutturali.
                  </p>
                  <h5>Cos'è l'operazione Six Pack?</h5>
                  <p>
                    Molti uomini desiderano ottenere addominali scolpiti, ma
                    nonostante l'esercizio intenso e la dieta, alcuni non
                    riescono a raggiungere questo obiettivo a causa di fattori
                    genetici, mancanza di tempo o altri ostacoli legati allo
                    stile di vita. Per questo motivo, l'operazione estetica dei
                    muscoli addominali, nota anche come intervento "Six Pack", è
                    diventata una soluzione popolare. La chirurgia dei muscoli
                    addominali consiste nella rimozione mirata del grasso dalla
                    zona addominale attraverso tecniche avanzate come la
                    liposuzione Vaser o laser. A differenza della liposuzione
                    tradizionale, la liposuzione Vaser utilizza la tecnologia ad
                    ultrasuoni per eliminare il grasso in punti strategici,
                    consentendo una maggiore precisione nel modellare gli
                    addominali. Questo metodo è particolarmente efficace per
                    ottenere l'aspetto scolpito e definito dei "sei pezzi",
                    garantendo un risultato naturale e proporzionato.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
