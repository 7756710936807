// WhatsAppButton.js
import React from "react";
import "./WhatsAppButton.css"; // Ensure you have this CSS file

const WhatsAppButton = () => {
  const handleClick = () => {
    window.open("https://wa.me/+905444465883", "_blank"); // Replace with your WhatsApp number
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <img
        src="assets/img/whatsapp1.jpg"
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </div>
  );
};

export default WhatsAppButton;
