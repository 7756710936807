import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const updateMobileView = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateMobileView);
    updateMobileView();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateMobileView);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleHomeClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    if (location.pathname !== "/") {
      navigate("/");
    } else {
      // Scroll to the top if already on /home
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${
            darkBg ? "bg-transparent" : "custom-nav white-bg"
          } ${scroll > headerTop ? "affix" : ""}`}
          style={{ backgroundColor: isMobile ? "#ffffff" : "" }}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <img
                  src="assets/img/logo-white-1x.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/logo.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a href="#" className="nav-link" onClick={handleHomeClick}>
                    pagina iniziale
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link  dropdown-toggle"
                    href="#/"
                    id="navbarDropdownPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Trapianto capelli
                  </a>
                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownPage"
                  >
                    <Link className="dropdown-item" to="/zaffiro">
                      Tecnica FUE Zaffiro
                    </Link>{" "}
                    <Link className="dropdown-item" to="/dhi">
                      Tecnica DHI
                    </Link>{" "}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link  dropdown-toggle"
                    href="#/"
                    id="navbarDropdownPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Chirurgia plastica
                  </a>
                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownPage"
                  >
                    <Link className="dropdown-item" to="/corpo">
                      <h6>corpo addominoplastica e liposcultura vaser</h6>
                    </Link>{" "}
                    <Link className="dropdown-item" to="/seno">
                      SENO E PROTESI{" "}
                    </Link>{" "}
                    <Link className="dropdown-item" to="/bbl">
                      BBL o BRAZILIAN BUTT LIFT{" "}
                    </Link>{" "}
                    <Link className="dropdown-item" to="/momy">
                      MOMMY MAKEOVER{" "}
                    </Link>{" "}
                    <Link className="dropdown-item" to="/six">
                      GINECOMASTIA E SIX PACK{" "}
                    </Link>{" "}
                    <Link className="dropdown-item" to="/lifting">
                      LIFTING BRACCIA E COSCIA{" "}
                    </Link>{" "}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link  dropdown-toggle"
                    href="#/"
                    id="navbarDropdownPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Odontoiatria e trattamenti dentali
                  </a>
                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownPage"
                  >
                    <Link className="dropdown-item" to="/Hollywood">
                      ⁠Hollywood smile e Faccette
                    </Link>{" "}
                    <Link className="dropdown-item" to="/impianti">
                      ⁠Impianti e chirurgia odontoiatrica
                    </Link>{" "}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link  dropdown-toggle"
                    href="#/"
                    id="navbarDropdownPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Chirurgia bariatrica
                  </a>
                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownPage"
                  >
                    <Link className="dropdown-item" to="/manica">
                      Manica gastrica o sleeve gastrectomy{" "}
                    </Link>{" "}
                    <Link className="dropdown-item" to="/bypass">
                      BYPASS GASTRICO{" "}
                    </Link>{" "}
                    <Link className="dropdown-item" to="/palloncino">
                      PALLONCINO GASTRICO
                    </Link>{" "}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
