import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Senoo from "../../components/pages-description/ChirugiaPlastica/Seno";
import Contact from "../../components/contact/Contact";
import BASeno from "../../BA/ChurigiaPlastica/seno";

export default function Seno() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader HeaderTitle="Chirugia Plastica" PageTitle="SENO E PROTESI" />
      <Senoo />
      <BASeno />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
