import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Dhi from "../../components/pages-description/TrapiantoCapelli/dhi";
import Contact from "../../components/contact/Contact";
import BAdhi from "../../BA/TrapiantoCapelli/BA-dhi";

export default function DHI() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader HeaderTitle="Trapianto Capelli" PageTitle="Tecnica DHI" />
      <Dhi />
      <BAdhi />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
