import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Momyy from "../../components/pages-description/ChirugiaPlastica/Momy";
import Contact from "../../components/contact/Contact";

export default function Momy() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader HeaderTitle="Chirugia Plastica" PageTitle="MOMMY MAKEOVER" />
      <Momyy />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
