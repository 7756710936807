import React from "react";

export default function Impiantii() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/impianto1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/imp2.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/impianto3.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">Odontoiatria e trattamenti dentali </h4>
                  <span>IMPIANTI DENTALI</span>
                </div>

                <div className="text-content mt-20">
                  <p>
                    L'impianto dentale è una radice dentale artificiale
                    realizzata in titanio, un materiale altamente resistente e
                    biocompatibile con il corpo umano. Viene inserito nell'osso
                    mascellare per sostituire i denti mancanti, offrendo una
                    soluzione stabile e duratura. Il trattamento con impianti
                    dentali permette di avere protesi che funzionano come denti
                    naturali, migliorando sia l'estetica che la funzionalità
                    della bocca. Questa procedura può essere applicata in vari
                    casi, dal rimpiazzo di un singolo dente mancante fino a
                    pazienti completamente edentuli (senza denti). Per poter
                    ricevere un impianto, l'osso mascellare deve avere una
                    quantità e densità sufficiente. In presenza di una carenza
                    ossea, potrebbe essere necessario un trattamento preliminare
                    per rinforzare l'osso. Inoltre, eventuali infezioni nella
                    cavità orale devono essere trattate prima di procedere con
                    l'impianto, garantendo così il successo dell'intervento. Gli
                    impianti dentali in Turchia sono molto apprezzati per la
                    loro alta qualità e il costo competitivo rispetto ad altri
                    paesi.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
