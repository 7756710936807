import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import TeamMember from "../../components/pages-description/TrapiantoCapelli/zaffiro";
import Contact from "../../components/contact/Contact";
import BAzaffiro from "../../BA/TrapiantoCapelli/BA-zaffiro";
export default function TrapiantoCapelli() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader HeaderTitle="Trapianto Capelli" PageTitle="Zaffiro" />
      <TeamMember />
      <BAzaffiro />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
