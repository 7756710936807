import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Hollywoodd from "../../components/pages-description/Odontoiatria/Hollywood";
import Contact from "../../components/contact/Contact";
import BAHollywood from "../../BA/Odontoiatria/hollywood";

export default function Hollywood() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Odontoiatria e trattamenti dentali"
        PageTitle="Hollywood smile e Faccette"
      />
      <Hollywoodd />
      <BAHollywood />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
