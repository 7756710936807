import React from "react";

export default function Momyy() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/seno.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />{" "}
                <img
                  src="assets/img/momy1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />{" "}
                <img
                  src="assets/img/momy2.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1"> CHIRURGIA PLASTICA</h4>
                  <span>MOMMY MAKEOVER</span>
                </div>

                <div className="text-content mt-20">
                  <h5>Cos'è Mommy Makeover?</h5>
                  <p>
                    Durante la gravidanza, la regione addominale si espande, il
                    tessuto adiposo sottocutaneo aumenta, il peso aumenta, il
                    volume del seno aumenta in modo significativo. Il peso
                    guadagnato con la nascita inizia a essere perso. La pelle si
                    restringe e si stringe. Il tessuto mammario si restringe e
                    affonda con l'allattamento al seno. Con la chirurgia del
                    makeover della mamma, aumentano il tessuto adiposo, il
                    rilassamento e le deformazioni del seno, dell'addome e del
                    corpo vengono eliminati. Eseguendo la liposuzione sul corpo,
                    il tessuto adiposo sottocutaneo viene ridotto. Il
                    rilassamento nell'addome viene eliminato con un mini
                    addominoplastica o un'addominoplastica completa. Se c'è
                    asimmetria e insufficienza nei glutei, le linee rotonde
                    vengono rivelate con l'iniezione di grasso. L'aumento del
                    seno, la riduzione del seno e il sollevamento vengono
                    eseguiti in base alle esigenze della paziente. In questo
                    modo, tutte le deformazioni corporee causate dalla
                    gravidanza vengono corrette.
                  </p>
                  <h5>Metodi di rifacimento per le mamme</h5>
                  <p>
                    L'estetica postpartum consiste in procedure che possono
                    essere modellate in base alle esigenze della persona. A
                    seconda delle esigenze della persona, i cambiamenti estetici
                    che si verificano nel corpo durante la gravidanza e dopo la
                    nascita sono gestiti in modo completo. A questo punto, le
                    richieste della persona sono in gran parte decisive. Ad
                    esempio, le persone che hanno bisogno di estetica vaginale e
                    riduzione del seno dopo il parto potrebbero non avere la
                    liposuzione come parte del pacchetto di rifacimento della
                    mamma. Alcune delle operazioni estetiche che possono essere
                    eseguite nell'ambito del mommy makeover possono essere
                    elencate come segue: Interventi chirurgici di sollevamento,
                    aumento o riduzione del seno: Il seno cresce durante questo
                    periodo come parte naturale del processo di gravidanza. Dopo
                    il periodo di nascita e allattamento, i seni tendono a
                    ridursi, perdono la loro pienezza e cedono. Con diverse
                    tecniche, il tessuto mammario può essere riportato al suo
                    stato pre-gravidanza o alla forma e alle dimensioni
                    immaginate. Dopo l'operazione al seno, si ottiene un aspetto
                    più liscio e più sano in termini di cosmetici. Chirurgia di
                    serraggio della vagina: La vagina che si espande e si
                    rilassa dopo la nascita può essere ristretta da una
                    procedura nota anche come vaginoplastica. Con la chirurgia
                    vaginale dopo la nascita, la vagina ha un aspetto più
                    stretto e stretto. Estetica vaginale: Con l'estetica
                    vaginale, che è definita come labioplastica in medicina, le
                    labbra interne (piccole labbra) ed esterne (grandi labbra)
                    della vagina, che si allentano e si allungano nel periodo
                    postpartum, vengono ripristinate alla loro forma precedente.
                    Il tessuto interessato a questo punto è principalmente le
                    labbra interne della vagina. Le labbra interne possono
                    estendersi oltre le labbra esterne. Grazie all'estetica
                    vaginale eseguita in questo contesto, le labbra della vagina
                    ricevono un aspetto estetico.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
