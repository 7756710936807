import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";

import Gastricoo from "../../components/pages-description/ChirurgiaBariatrica/BypassGastrico";
import Contact from "../../components/contact/Contact";
import Team from "../../components/pages-description/Service";

export default function Gastrico() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirurgia bariatrica"
        PageTitle="BYPASS GASTRICO"
      />
      <Gastricoo />
      <Team />

      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
