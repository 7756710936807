import React from "react";
import { Link } from "react-router-dom";
const Team = ({ hasTitle, bgColor }) => {
  const imageStyle = {
    width: "200px",
    height: "200px",
    objectFit: "cover",
    borderRadius: "50%",
    display: "block",
    margin: "0 auto", // center the image on smaller screens
    position: "relative", // Ensure the image container is relative
  };

  const teamInfoStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(128, 0, 128, 0.6)", // Purple color with transparency
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  };

  // CSS Media Queries

  return (
    <>
      <section id="team" className={`team-member-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                {hasTitle ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Lovely Team
                  </span>
                ) : (
                  ""
                )}
                <h2>Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <Link to="/zaffiro">
                <div className="single-team-member position-relative">
                  <div className="team-image">
                    <img
                      src="assets/img/dhi-comparaison.jpg"
                      alt="Team Members"
                      className="img-fluid rounded-circle"
                      style={imageStyle}
                    />
                  </div>
                  <div
                    style={teamInfoStyle}
                    className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center"
                  >
                    <h5 className="mb-0">Trapianto Capelli</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
              <Link to="/hollywood">
                <div className="single-team-member position-relative">
                  <div className="team-image">
                    <img
                      src="assets/img/hollywood1.jpg"
                      alt="Team Members"
                      className="img-fluid rounded-circle"
                      style={imageStyle}
                    />
                  </div>
                  <div
                    style={teamInfoStyle}
                    className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center"
                  >
                    <h5 className="mb-0">Hollywood Smile</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
              <Link to="/impianti">
                <div className="single-team-member position-relative">
                  <div className="team-image">
                    <img
                      src="assets/img/impianto1.jpg"
                      alt="Team Members"
                      className="img-fluid rounded-circle"
                      style={imageStyle}
                    />
                  </div>
                  <div
                    style={teamInfoStyle}
                    className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center"
                  >
                    <h5 className="mb-0">Impianto</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-sm-6">
              <Link to="/corpo">
                <div className="single-team-member position-relative">
                  <div className="team-image">
                    <a href="/trapianto-capelli" className="team-image">
                      <img
                        src="assets/img/abdo.jpg"
                        alt="Team Members"
                        className="img-fluid rounded-circle"
                        style={imageStyle}
                      />
                    </a>
                  </div>
                  <div
                    style={teamInfoStyle}
                    className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center"
                  >
                    <h5 className="mb-0">Chirugia plastica</h5>
                    <h6></h6>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Team;
