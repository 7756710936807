import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Palloncinoo from "../../components/pages-description/ChirurgiaBariatrica/palloncino";
import Contact from "../../components/contact/Contact";

export default function Palloncino() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirurgia bariatrica"
        PageTitle="PALLONCINO GASTRICO"
      />
      <Palloncinoo />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
