import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import BBLL from "../../components/pages-description/ChirugiaPlastica/BBL";
import Contact from "../../components/contact/Contact";
import BaBbl from "../../BA/ChurigiaPlastica/bbl";

export default function BBL() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirugia Plastica"
        PageTitle="BBL o BRAZILIAN BUTT LIFT"
      />
      <BBLL />
      <BaBbl />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
