import React from "react";

export default function Senoo() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/seno2.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/seno1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1"> CHIRURGIA PLASTICA</h4>
                  <span>SENO E PROTESI</span>
                </div>

                <div className="text-content mt-20">
                  <h5>Aumento del seno o mastoplastica addittiva</h5>
                  <p>
                    La perdita di forma del tessuto mammario può essere causata
                    da fluttuazioni di peso, cambiamenti post-gravidanza o
                    piccoli difetti congeniti. Col passare del tempo, fattori
                    come l'invecchiamento e la forza di gravità possono
                    ulteriormente contribuire al rilassamento del seno. Per
                    affrontare questi problemi, è possibile sottoporsi a un
                    intervento di aumento del seno, ottenendo le dimensioni
                    desiderate. Questa procedura prevede l'uso di protesi
                    mammarie o l'iniezione di grasso per un risultato naturale.
                    L'aspetto più importante durante l'operazione è garantire la
                    simmetria e un aspetto armonioso del seno. I nostri
                    professionisti ti consiglieranno il metodo più adatto in
                    base alle tue esigenze.
                  </p>
                  <h5>Mastoplastica riduttiva</h5>
                  <p>
                    L'eccessiva crescita del seno può essere causata da fattori
                    come predisposizione genetica, aumento di peso, cambiamenti
                    ormonali, gravidanza e allattamento. Donne con seni
                    voluminosi e cadenti possono sperimentare dolori a schiena,
                    spalle e collo, oltre a sudorazione, eruzioni cutanee,
                    difficoltà respiratorie e problemi alle ossa. Anche le
                    attività fisiche e la scelta dell'abbigliamento possono
                    risultare difficoltose. La mastoplastica riduttiva, o
                    chirurgia di riduzione del seno, mira a risolvere questi
                    problemi rimuovendo l'eccesso di pelle, grasso e tessuto
                    mammario. Il risultato è un seno più piccolo, leggero,
                    simmetrico e proporzionato. Questa procedura può anche
                    ridurre la dimensione dei capezzoli e viene eseguita non
                    solo per motivi estetici, ma anche per alleviare
                    problematiche fisiche.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
