import React from "react";

export default function TeamMember() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/zaffiro2.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/zaffiro1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/dhi.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">Trapianto di Capelli </h4>
                  <span>FUE ZAFFIRO in Turchia</span>
                </div>

                <div className="text-content mt-20">
                  <p>
                    Il trapianto di capelli FUE (Follicular Unit Extraction) è
                    una procedura che prevede l'estrazione delle unità
                    follicolari dalla parte posteriore e laterale della testa,
                    chiamate "zone donatrici", per essere trapiantate nelle aree
                    colpite da diradamento. La tecnica FUE utilizza un
                    micromotore per facilitare l'estrazione delle unità
                    follicolari: una testa ad alta precisione in zaffiro crea
                    un'incisione attorno all'unità follicolare, consentendo una
                    facile estrazione con l'ausilio di una pinza medica.
                  </p>
                  <h5>
                    Desideri sottoporti ad un trapianto di capelli? Consulta i
                    nostri medici, potrai fare tutte le domande che vuoi per
                    chiarirti le idee e fare una valutazione GRATUITA
                  </h5>
                  <p>
                    In questa sezione del nostro articolo, ti daremo un'idea di
                    ciò che potrai sperimentare in Turchia. Ecco alcuni aspetti
                    importanti da considerare prima di partire e ciò che
                    troverai presso di noi: Prima di tutto, è fondamentale
                    conoscere nel dettaglio i contenuti dei pacchetti offerti.
                    Questo ti permetterà di avere una chiara comprensione del
                    processo e di sentirti più a tuo agio. Il tuo pacchetto, in
                    ogni caso, include la possibilità di essere accompagnato da
                    una persona a tua scelta. Potrai portare un amico o un'amica
                    per farti compagnia. Tuttavia, se non hai questa
                    possibilità, non preoccuparti: saremo noi a prenderci cura
                    di te. Ti consigliamo di avere con te del denaro contante al
                    tuo arrivo, poiché in Turchia potresti incontrare difficoltà
                    a effettuare pagamenti con la carta. Non dimenticare di
                    portare con te tutti gli effetti personali di cui potresti
                    aver bisogno. Considera anche di portare una macchina
                    fotografica, perché il tuo viaggio in Turchia non sarà solo
                    per il trapianto di capelli. Avrai anche l'opportunità di
                    fare un tour turistico. Qualunque sia la città in cui ti
                    trovi in questo paese unico, ti aspetta un meraviglioso
                    viaggio di scoperta, che inizierà già dal tuo arrivo in
                    aeroporto, dove il nostro team ti accoglierà calorosamente.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
