import React from "react";

export default function BBLL() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/bbl.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/bbl2.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/bbl1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1"> CHIRURGIA PLASTICA</h4>
                  <span>BBL o BRAZILIAN BUTT LIFT</span>
                </div>

                <div className="text-content mt-20">
                  <h5>Cos'è il Brazilian Butt Lift?</h5>
                  <p>
                    Il Brazilian Butt Lift (BBL), conosciuto in Turchia anche
                    come sollevamento del sedere, è una procedura chirurgica che
                    modella i glutei e le anche, offrendo ai pazienti un aspetto
                    più tonico e armonioso. Utilizzando tecniche come
                    l'iniezione di grasso, protesi in silicone o riempimenti, il
                    BBL mira a mantenere un aspetto naturale, enfatizzando forme
                    morbide e femminili. L'obiettivo principale è rendere i
                    glutei più pieni, sollevati e ben definiti, migliorando
                    anche la linea tra vita e fianchi per un aspetto giovanile e
                    proporzionato. La chirurgia del Brazilian Butt Lift è
                    pensata per aiutare le donne a ottenere glutei più
                    voluminosi e tonici, modellando l'area in modo che risulti
                    naturale e armoniosa rispetto al resto del corpo. Non si
                    tratta solo di aumentare il volume, ma di considerare il
                    corpo nel suo insieme, pianificando un intervento su misura.
                  </p>
                  <h5>Metodi di sollevamento brasiliano dei glutei</h5>
                  <p>
                    Esistono due principali tecniche: il trasferimento di grasso
                    e l'uso di protesi in silicone. La scelta della tecnica più
                    adatta dipende dalle caratteristiche anatomiche e dalle
                    esigenze specifiche del paziente. Le principali
                    problematiche affrontate includono la perdita di tono e
                    l’assenza di curve definite nella zona glutea. In chirurgia
                    estetica, sono state sviluppate diverse soluzioni per
                    risolvere questi problemi.
                  </p>
                  <h5>Trasferimento di grasso</h5>
                  <p>
                    Il trasferimento di grasso è ideale per chi possiede
                    sufficiente tessuto adiposo per ottenere i risultati
                    desiderati. Il grasso in eccesso, prelevato da aree come
                    addome, vita e fianchi tramite liposuzione, viene reinserito
                    nei glutei con una tecnica speciale per modellare e
                    scolpire. Con questo metodo, mentre la parte superiore delle
                    gambe viene assottigliata, si ottiene un effetto curvato sui
                    glutei, senza l'uso di materiali estranei al corpo.
                  </p>
                  <h5>Riempimento in silicone</h5>
                  <p>
                    Un'alternativa è l'uso di protesi in silicone,
                    particolarmente indicato per chi desidera un effetto più
                    sollevato e definito. Le protesi in silicone vengono
                    inserite attraverso incisioni nella parte superiore dei
                    glutei e dell'addome per sollevare e rassodare l'area. In
                    aggiunta, possono essere applicate tecniche di riduzione del
                    grasso per rimodellare i fianchi e ottenere un risultato più
                    armonioso e tonico.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
