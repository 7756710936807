import React from "react";

export default function Corpoo() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/abdo1.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1"> CHIRURGIA PLASTICA</h4>
                  <span>CORPO ADDOMINOPLASTICA E LIPOSCULTURA VASER</span>
                </div>

                <div className="text-content mt-20">
                  <p>
                    Con il tempo, fattori fisici e ormonali come il rapido
                    aumento o perdita di peso e la gravidanza possono ridurre
                    l'elasticità della pelle addominale, portando a rilassamento
                    e cedimento. Queste deformazioni sono spesso accompagnate da
                    una diminuzione della qualità della pelle e dalla comparsa
                    di smagliature. La chirurgia dell'addominoplastica è una
                    procedura consolidata, utilizzata da anni per correggere
                    tali problemi. L'obiettivo non è solo quello di rimuovere
                    l'eccesso di pelle, ma anche di rimodellare il corpo.
                    Spesso, la liposuzione viene combinata con
                    l'addominoplastica: in molti casi, infatti, la liposcultura
                    vaser è abbinata per rimuovere il grasso in eccesso,
                    scolpire l'addome e ottenere risultati ottimali.
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
