import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Liftingg from "../../components/pages-description/ChirugiaPlastica/lifting";
import Contact from "../../components/contact/Contact";

export default function Lifting() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirugia Plastica"
        PageTitle="LIFTING BRACCIA E COSCIA "
      />
      <Liftingg />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
