import React from "react";

export default function DHI() {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src="assets/img/dhi-comparaison.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/dhi-schema.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
                <img
                  src="assets/img/dhi.jpg"
                  alt="member"
                  className="img-fluid rounded shadow-sm"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">Trapianto di Capelli </h4>
                  <span>DHI in Turchia</span>
                </div>

                <div className="text-content mt-20">
                  <p>
                    Il trapianto di capelli DHI, acronimo di "Direct Hair
                    Implantation", è una tecnica innovativa che utilizza una
                    speciale penna medica chiamata Choi, la quale deve essere
                    maneggiata esclusivamente da esperti. Con questa penna, il
                    medico raccoglie gli innesti e, senza necessità di incisioni
                    o canali, inserisce direttamente i follicoli piliferi nel
                    cuoio capelluto. Il trapianto con questa penna avviene in
                    due fasi, permettendo di aprire il canale e inserire
                    l'innesto contemporaneamente. La penna implanter,
                    caratterizzata da una struttura cilindrica molto sottile,
                    consente di inserire i capelli direttamente nell'ago,
                    rendendo questo metodo uno dei più pratici disponibili.
                  </p>
                  <h5>Prima dell'Operazione di Trapianto di Capelli DHI</h5>
                  <p>
                    Prima di sottoporsi a un trapianto di capelli DHI, è
                    fondamentale seguire alcune precauzioni per garantire la
                    salute delle radici trapiantate e favorire una rapida
                    guarigione dell'area donatrice. Ecco alcuni consigli:
                    <br />
                    • Non fumare per almeno 2 giorni prima dell'intervento.
                    <br />
                    • Evita il consumo di alcol per almeno 3 giorni prima della
                    procedura.
                    <br />
                    • Non assumere aspirina o altri fluidificanti del sangue nei
                    10 giorni precedenti l'intervento.
                    <br />
                    • La riduzione della lunghezza dei capelli verrà eseguita
                    nella nostra clinica prima dell'operazione.
                    <br />
                    • La mattina dell'intervento, lavati i capelli con una
                    doccia.
                    <br />
                    • Evita l'esposizione diretta al sole sulla testa per almeno
                    2 settimane prima della procedura, soprattutto in estate. È
                    importante che il cuoio capelluto non sia abbronzato o
                    scottato.
                    <br />• Indossa abiti larghi come camicie o magliette con
                    zip quando arrivi in clinica per evitare di danneggiare i
                    follicoli trapiantati.
                  </p>
                  <h5>
                    Desideri sottoporti ad un trapianto di capelli? Consulta i
                    nostri medici, potrai fare tutte le domande che vuoi per
                    chiarirti le idee e fare una valutazione GRATUITA Dopo
                    l'Operazione di Trapianto di Capelli DHI
                  </h5>
                  <p>
                    Per garantire un recupero ottimale e il successo del
                    trapianto di capelli DHI, è essenziale seguire alcune linee
                    guida post-operatorie: • Evita di consumare caffè, sigarette
                    o altre sostanze stimolanti subito dopo l'intervento. •
                    Proteggi il cuoio capelluto da traumi o urti. • Non bagnare
                    il cuoio capelluto fino al primo lavaggio post-operatorio. •
                    Evita attività che possano causare sudorazione eccessiva. •
                    Puoi iniziare a indossare cappelli o altri accessori una
                    settimana dopo la procedura. • Dormi supino con la testa
                    leggermente sollevata per ridurre il gonfiore.
                  </p>
                  <h5>Vuoi saperne di più sul trapianto di capelli?</h5>
                  <p>
                    Consulta i nostri medici per chiarire qualsiasi dubbio e
                    ricevere una valutazione gratuita. Siamo qui per rispondere
                    a tutte le tue domande!
                  </p>
                </div>
                <ul className="team-social-list list-inline mt-4">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/profile.php?id=61566711771340"
                      className="color-primary"
                    >
                      <span className="ti-facebook"></span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/int.medical.plus/"
                      className="color-primary"
                    >
                      <span className="ti-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5"></div>
        </div>
      </section>
    </>
  );
}
