import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Sixx from "../../components/pages-description/ChirugiaPlastica/six";
import Contact from "../../components/contact/Contact";

export default function Six() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirugia Plastica"
        PageTitle="SLIFTING BRACCIA E COSCIA"
      />
      <Sixx />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
