import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../components/others/NotFound";
import Home from "../themes/index/Home";
import Zaffiro from "../pages/TrapiantoCapelli/zaffiro";
import DHI from "../pages/TrapiantoCapelli/dhi";
import ScrollToTop from "./ScrollToTop";
import Corpo from "../pages/ChurigiaPlastica/Corpo";
import Hollywood from "../pages/Odontoiatria/Hollywood";
import Impianti from "../pages/Odontoiatria/impianti";
import Manica from "../pages/chiriguaBariatrica/Manica";
import Gastrico from "../pages/chiriguaBariatrica/BypassGastrico";
import Palloncino from "../pages/chiriguaBariatrica/palloncino";
import Seno from "../pages/ChurigiaPlastica/seno";
import BBL from "../pages/ChurigiaPlastica/Bbl";
import Momy from "../pages/ChurigiaPlastica/Momy";
import Six from "../pages/ChurigiaPlastica/six";
import Lifting from "../pages/ChurigiaPlastica/lifting";
import WhatsAppButton from "../components/whatsApp-button/whatsApp";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact="/" element={<Home />} />

        <Route path="/zaffiro" element={<Zaffiro />} />
        <Route path="/dhi" element={<DHI />} />

        <Route path="/Hollywood" element={<Hollywood />} />
        <Route path="/impianti" element={<Impianti />} />

        <Route path="/manica" element={<Manica />} />
        <Route path="/bypass" element={<Gastrico />} />
        <Route path="/palloncino" element={<Palloncino />} />

        <Route path="/corpo" element={<Corpo />} />
        <Route path="/seno" element={<Seno />} />
        <Route path="/bbl" element={<BBL />} />
        <Route path="/momy" element={<Momy />} />
        <Route path="/six" element={<Six />} />
        <Route path="/lifting" element={<Lifting />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <WhatsAppButton />
    </>
  );
};

export default AppRoutes;
