import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pages-description/PageHeader";
import Corpoo from "../../components/pages-description/ChirugiaPlastica/Corpoo";
import Contact from "../../components/contact/Contact";
import BACorpo from "../../BA/ChurigiaPlastica/corpo";

export default function Corpo() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Chirugia Plastica"
        PageTitle="CORPO ADDOMINOPLASTICA E LIPOSCULTURA VASER"
      />
      <Corpoo />
      <BACorpo />
      <Contact bgColor="gray-light-bg" />

      <Footer space />
    </Layout>
  );
}
